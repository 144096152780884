<template>
  <button
    @click="printBarcode()"
    class="btn btn-primary btn-sm print-btn mb-3 mb-lg-0"
  >
    <i class="fas fa-file-pdf"></i> {{ $t("orders.print_all") }}
  </button>
  <div v-if="selected_orders" id="barcodes">
    <div class="barocde" v-for="(order, index) in selected_orders" :key="index">
      <barcode-invoice :orderInfo="order"></barcode-invoice>
      <div class="pagebreak"></div>
    </div>
  </div>
</template>
<script>
import BarcodeInvoice from "./BarcodeInvoice.vue";
export default {
  name: "BarcodePrint",
  components: {
    BarcodeInvoice,
  },
  data() {
    return {
      rendered: false,
    };
  },
  props: {
    selected_orders: [String, Number],
  },
  methods: {
    printBarcode() {
      this.rendered = true;
      const prtHtml = document.getElementById("barcodes").innerHTML;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
          <html>
          <head>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
              @media print {
                @page {
                  size: 4in 6in;
                  margin: 0;
                  padding: 0;
                }
                html, body { 
                  font-family: 'Roboto', sans-serif;
                  position: relative;
                  width: 100%;
                  height: 100%;
                  max-width: 100%;
                  max-height: 97%;
                  margin: 0;
                  padding: 0;
                }
                svg {
                  width: 100%;
                  height: 100%;
                  max-width: 100%;
                  max-height: 100%;
                }
                .pagebreak {
                  clear: both;
                  page-break-after: always;
                }
              }
              // Print Sm
              .sm-invoice {
                color: #000;
                background-color: #fff;
                width: 95mm;
                height: auto;
                border: 1px solid #000;
                border-radius: 8px;
                padding: 12px;
                direction: ltr;
                text-align: start;
              }
              .sm-invoice img {
                width: 80px;
                height: auto;
                object-fit: contain;
              }
              .sm-invoice .separator {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 6px;
                width: 1px;
                height: 100%;
                background-color: #000;
              }
              .printSm-box {
                border-bottom: 1px solid #000;
              }
              .printSm-box h6 {
                font-size: 14px;
                font-weight: 400;
              }
              .printSm-box p {
                font-size: 13px;
                font-weight: 600;
              }
              .printSm-box .in {
                width: 30px;
                height: 30px;
                background: #000;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
              }
              .no-b-b {
                border-bottom: 0 !important;
              }
              .sm-invoice svg {
                width: 100%;
              }
              .bg-gradient-main{
                background: linear-gradient(87deg, #65d1d1 0, #65a4d1 100%) !important;
                color: #fff;
                text-align: center;
                font-weight: 700;
              }
            </style>           
          </head>
          <body>
              ${prtHtml}
          </body>
          </html>`);

      WinPrint.document.close();
      WinPrint.document.title = "invoices";
      WinPrint.print();
    },
  },
};
</script>
<style scoped>
#barcodes {
  display: none;
}
</style>
