<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7 position-relative">
      <div class="invoice-design-container">
        <button @click="print()" class="btn btn-primary m-auto d-block">
          {{ $t("global.print") }}
        </button>
        <div class="row">
          <div class="col-10 m-auto" id="printMe">
            <div class="invoice-design">
              <div class="invoice-design-child">
                <div class="row align-items-center">
                  <div class="col-4 text-start company-name">
                    {{ get_company_name }}
                  </div>
                  <div class="col-4 text-center">
                    <img :src="get_company_logo" alt="Logo" width="120" />
                  </div>
                  <div class="col-4 text-end company-name">
                    {{ get_company_name }}
                  </div>
                </div>
                <div class="text-center font-weight-bold mt-3">
                  <i class="fas fa-phone"></i> {{ get_company_phone }}
                </div>
                <div class="row justify-content-between align-items-center">
                  <div class="col-4">
                    <div class="line-row">
                      <span class="span1">التاريخ</span>
                      <span class="span2">Date</span>
                    </div>
                  </div>
                  <div class="col-4 text-end">
                    <div class="line-row d-block text-end">
                      <span class="span3">No.</span>
                    </div>
                  </div>
                </div>
                <div class="line-row name-row">
                  <span class="span1">السيد/السادة</span>
                  <span class="span2">Mr./M/s</span>
                </div>
                <div class="dir-ltr">
                  <table>
                    <thead>
                      <th>
                        <span>الرقم</span>
                        <span>No</span>
                      </th>
                      <th>
                        <span>التفاصيل</span>
                        <span>Description</span>
                      </th>
                      <th>
                        <span>العدد</span>
                        <span>Qty</span>
                      </th>
                      <th>
                        <span>السعر</span>
                        <span>Rale</span>
                      </th>
                      <th>
                        <span>المبلغ</span>
                        <span>Amount</span>
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row name-row">
                    <div class="col-6">
                      <div class="line-row d-block text-start">
                        <span class="span3">Advance</span>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="line-row d-block text-start">
                        <span class="span3">balance</span>
                      </div>
                    </div>
                  </div>
                  <div class="line-row name-row dir-rtl">
                    <span class="span1">المجموع: درهم</span>
                    <span class="span2">Total: Dhs</span>
                  </div>
                  <div
                    class="row justify-content-between align-items-center dir-rtl"
                  >
                    <div class="col-6">
                      <div class="line-row">
                        <span class="span1">توقيع المستلم</span>
                        <span class="span2">Receiver Sign</span>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="line-row">
                        <span class="span1">التوقيع</span>
                        <span class="span2">Signature</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      output: null,
    };
  },
  computed: {
    get_company_name() {
      return this.$store.getters.company_name;
    },
    get_company_phone() {
      return this.$store.getters.company_phone;
    },
    get_company_logo() {
      return this.$store.getters.company_logo;
    },
  },
  methods: {
    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printMe").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open();

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            ${prtHtml}
        </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //   WinPrint.close();
    },
  },
};
</script>

<style scoped>
@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 297mm;
    height: 420mm;
  }
  /* ... the rest of the rules ... */
}
.invoice-design-container {
  background-color: #ccc;
}
.invoice-design {
  margin: auto;
  background-color: #fff;
  color: #000;
  padding: 20px;
}
.invoice-design .invoice-design-child {
  border: 2px solid #000;
  padding: 20px;
}
.invoice-design .company-name {
  font-weight: 700;
  font-size: 34px;
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}
.line-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.line-row::after {
  content: "";
  position: absolute;
  border-bottom: 1px dashed #000;
  left: 0;
  right: 0;
  bottom: 16px;
}
.name-row {
  margin-top: 20px;
}
.line-row span.span1 {
  text-align: start;
  background: #fff;
  z-index: 9;
  height: 35px;
  padding-inline-end: 6px;
}
.line-row span.span2 {
  text-align: end;
  background: #fff;
  z-index: 9;
  height: 35px;
  padding-inline-start: 6px;
}
.invoice-design span.span3 {
  direction: ltr;
  display: inline-block;
  height: 35px;
  background: #fff;
  position: relative;
  z-index: 9;
  padding-inline-end: 6px;
}
.dir-rtl {
  direction: rtl;
}
.dir-ltr {
  direction: ltr;
}
.invoice-design table {
  width: 100%;
  margin-top: 40px;
}
.invoice-design th,
.invoice-design td {
  border: 1px solid #000;
  text-align: center !important;
}
.invoice-design th span {
  display: block;
}
.invoice-design td {
  height: 800px !important;
}
</style>
