<template>
  <base-loader v-if="loading"></base-loader>
  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-else class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <select
            class="btn btn-primary btn-sm text-left"
            @change="getFilterdOrders($event)"
          >
            <option value="">{{ $t("tables.filter by status") }}</option>
            <option value="all">{{ $t("tables.all") }}</option>
            <option
              v-for="(status, index) in statuses"
              :key="index"
              :value="status.id"
            >
              {{ status.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="orders"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.status") }}</th>
          <th>{{ $t("tables.courier name") }}</th>
          <th>{{ $t("tables.receiver") }}</th>
          <th>{{ $t("tables.order price") }}</th>
          <th>{{ $t("tables.state") }}</th>
          <th>{{ $t("tables.address") }}</th>
          <th>{{ $t("tables.date") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <td class="budget">
            <span v-if="row.item.statuses.length > 0">
              {{ row.item.statuses[row.item.statuses.length - 1].name }}
            </span>
          </td>
          <th scope="row">
            <span class="name mb-0 text-sm">
              {{ row.item.courier_name }}
              <span class="badge bg-success text-white">{{
                row.item.courier_phone
              }}</span>
            </span>
          </th>
          <td scope="row">
            <span class="name mb-0 text-sm">
              {{ row.item.receiver_name }}
              <span class="badge bg-success text-white">
                {{ row.item.receiver_phone }}</span
              >
            </span>
          </td>
          <td class="budget">
            {{ row.item.price }}
          </td>
          <td class="budget">
            {{ row.item.state }}
          </td>
          <td class="budget">
            {{ row.item.address }}
          </td>
          <td class="budget">
            {{
              moment(row.item.created_at).format(
                "ddd [-] DD[-]MM[-]YYYY [/] HH:mm a"
              )
            }}
          </td>
          <td class="budget text-right">
            <router-link
              :to="`/orders/${row.item.id}`"
              class="btn btn-primary btn-sm"
              ><i class="fas fa-eye"></i
            ></router-link>
            <router-link
              :to="`/orders/track/${row.item.id}`"
              class="btn btn-danger btn-sm"
              ><i class="fas fa-map-marker-alt"></i
            ></router-link>
            <!-- <select class="btn btn-success btn-sm text-left" @change="chgOrderStatus(row.item.id,$event)">
                <option value="">Change Status</option>
                <option v-for="(status,index) in statuses" :key="index" :value="status.id">{{status.name}}</option>
            </select> -->
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :pagination="pagination"
        :paginationLinks="paginationLinks"
        v-on:pagination-prev="getOrders($event)"
        v-on:pagination-num="getOrders(PaginationNum + $event)"
        v-on:pagination-next="getOrders($event)"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "orders-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      orders: "",
      statuses: "",
      targetStatus: "",
      newStatus: "",

      pagination: "",
      paginationLinks: "",
      // currentPage: "",
      // For Pagination num
      PaginationNum: "",
    };
  },
  created() {
    this.getOrders("companies/orders/index?page=1");
    this.getStatus();
    this.moment = moment;
  },
  methods: {
    getOrders: function ($pagination) {
      this.loading = true;
      if (this.targetStatus) {
        // To Change Pagination to filtering pagination
        axios
          .post(
            $pagination,
            { status: this.targetStatus },
            {
              headers: {
                "Accept-Language": "en",
              },
            }
          )
          .then((response) => {
            if (response.data.error) {
              // console.log("Error");
            } else {
              this.orders = response.data.data;
              this.pagination = response.data.meta;
              this.paginationLinks = response.data.links;
              this.PaginationNum = "companies/orders/search?page=";
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                // console.log(error.response.headers);
              } else if (error.request) {
                // console.log(error.request);
              } else {
                // console.log('Error', error.message);
              }
            } else {
              this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      } else {
        //If Not Filtering Orders
        axios
          .get($pagination, {
            headers: {
              "Accept-Language": "en",
            },
          })
          .then((response) => {
            if (response.data.error) {
              //
            } else {
              this.orders = response.data.data;
              this.pagination = response.data.meta;
              this.paginationLinks = response.data.links;
              this.PaginationNum = "companies/orders/index?page=";
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                //
              } else if (error.request) {
                //
              } else {
                //
              }
            } else {
              this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    // Get statues to filter
    getStatus: function () {
      this.loading = true;
      axios
        .get("companies/orders/statuses", {
          headers: {
            "Accept-Language": "ar",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            this.statuses = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log(error.response);
          } else if (error.request) {
            // console.log(error.request);
          } else {
            // console.log('Error', error.message);
          }
        })
        .finally(() => (this.loading = false));
    },

    // Fiter Orders
    getFilterdOrders: function () {
      // If choose 'all' in Filterd Box
      if (event.target.value == "all") {
        this.targetStatus = "";
        this.getOrders("companies/orders/index?page=1");
      } else {
        this.loading = true;
        this.targetStatus = event.target.value;
        const headers = {
          "Accept-Language": "ar",
        };
        axios
          .post(
            "companies/orders/search?page=1",
            { status: this.targetStatus },
            { headers: headers }
          )
          .then((response) => {
            if (response.data.error) {
              // console.log("Error");
            } else {
              this.orders = response.data.data;
              this.pagination = response.data.meta;
              this.paginationLinks = response.data.links;
              this.PaginationNum = "companies/orders/search?page=";
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                // console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
              }
            } else {
              this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    // Will be move to courier app
    // Change Order Status
    // chgOrderStatus: function($order_id){
    //     this.loading = true;
    //     this.newStatus = event.target.value;
    //     const headers = {
    //         'Accept-Language': 'ar',
    //     }
    //     axios.post('companies/orders/statusChange',{order_id:$order_id, status_id:this.newStatus},{headers: headers})
    //     .then(response => {
    //       if(response.data.error){
    //         this.errorMsg = [response.data.message];
    //           return createToast(response.data.message,
    //           {
    //           type: 'danger',
    //           timeout: 4000
    //           });
    //       }else{
    //         if(response.data.status==0){
    //           return createToast(response.data.message,
    //           {
    //           type: 'danger',
    //           timeout: 4000
    //           });
    //         }else{
    //           console.log(this.pagination.path+'?page='+this.pagination.current_page);
    //           this.getOrders(this.pagination.path+'?page='+this.pagination.current_page);
    //           // return createToast(response.data.message,
    //           // {
    //           // type: 'success',
    //           // timeout: 3000
    //           // });
    //         }
    //         // this.orders = response.data.data;
    //         // this.pagination = response.data.meta;
    //         // this.paginationLinks = response.data.links;
    //         // this.PaginationNum = 'companies/orders/search?page=';
    //       }
    //     })
    //     .catch((error) => {
    //       if(window.navigator.onLine){
    //         if (error.response) {
    //             // console.log(error.response.data);
    //         } else if (error.request) {
    //             // console.log(error.request);
    //         } else {
    //             // console.log('Error', error.message);
    //         }
    //       }else{
    //         this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
    //       }
    //   }).finally(() => (this.loading = false));
    // },
  },
};
</script>
