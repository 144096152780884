<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Nqal"
      title="Nqal"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'ni ni-chart-pie-35 text-main',
            path: '/dashboard',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.all users'),
            icon: 'ni fas fa-users text-main',
            path: '/users',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.new user'),
            icon: 'ni ni-chart-pie-35 text-main',
            path: '/new-user',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.roles'),
            icon: 'ni ni-chart-pie-35 text-main',
            path: '/roles',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.add role'),
            icon: 'ni ni-chart-pie-35 text-main',
            path: '/add-role',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.traders'),
            icon: 'ni ni-shop text-main',
            path: '/traders',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.couriers'),
            icon: 'ni ni-delivery-fast text-main',
            path: '/couriers',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.customers'),
            icon: 'ni fas fa-users text-main',
            path: '/company-customers',
          }"
        />
        <div class="dropdown-divider"></div>

        <sidebar-item
          :link="{
            name: $t('sidebar.orders'),
            icon: 'ni ni-cart text-main',
            path: '/orders',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.barcode_prints'),
            notify: $t('global.new'),
            icon: 'ni fas fa-barcode text-main',
            path: '/print-barcode',
          }"
        />
        <!-- <sidebar-item
          :link="{
            name: $t('sidebar.create order'),
            icon: 'ni fas fa-plus text-main',
            path: '/create-order',
          }"
        /> -->
        <sidebar-item
          :link="{
            name: $t('sidebar.order auction'),
            icon: 'ni far fa-user-circle text-main',
            path: '/coming-offer-auction',
          }"
        />
        <div class="dropdown-divider"></div>
        <!-- Reports -->
        <sidebar-item
          :link="{
            name: $t('sidebar.generate company report'),
            icon: 'ni far fa-file-alt text-main',
            path: '/generate-companies-report',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.generate courier report'),
            icon: 'ni fas fa-file-alt text-main',
            path: '/generate-couriers-report',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.generate trader report'),
            icon: 'ni fas fa-file-alt text-main',
            path: '/generate-traders-report',
          }"
        />
        <!-- Reviews -->
        <sidebar-item
          :link="{
            name: $t('sidebar.company review'),
            icon: 'ni fas fa-star-half-alt text-main',
            path: '/company-reviews',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.wallet'),
            icon: 'ni fas fa-wallet text-main',
            path: '/wallet',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.update working hours'),
            icon: 'ni ni-calendar-grid-58 text-main',
            path: '/working-hours',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.update profile'),
            icon: 'ni ni-single-02 text-main',
            path: '/update-profile',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.invoice_design'),
            icon: 'ni fas fa-file text-main',
            path: '/invoice-design',
          }"
        />
        <div class="dropdown-divider"></div>
        <sidebar-item
          :link="{
            name: $t('sidebar.link_to_websites'),
            icon: 'ni fas fa-file text-main',
            path: '',
            disabled: 1,
            notify: $t('global.soon'),
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.create_order'),
            icon: 'ni fas fa-file text-main',
            path: '',
            disabled: 1,
            notify: $t('global.soon'),
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.barcode_prints'),
            icon: 'ni fas fa-file text-main',
            path: '',
            disabled: 1,
            notify: $t('global.soon'),
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
