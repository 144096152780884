<template>
  <base-loader v-if="loading"></base-loader>
  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-else class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <barcode-btn-print
            :selected_orders="selected_orders"
          ></barcode-btn-print>
          <select
            class="btn btn-primary btn-sm text-left"
            @change="getFilteredOrders($event)"
          >
            <option value="">{{ $t("tables.filter by status") }}</option>
            <option value="all">{{ $t("tables.all") }}</option>
            <option
              v-for="(status, index) in statuses"
              :key="index"
              :value="status.id"
            >
              {{ status.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="orders"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>
            <input
              class="form-check-input"
              type="checkbox"
              true-value="1"
              false-value="0"
              v-model="checkAll"
              @change="check_all"
            />
          </th>
          <th>#</th>
          <th>{{ $t("tables.status") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            <input
              class="form-check-input"
              type="checkbox"
              :value="row.item"
              v-model="selected_orders"
            />
          </td>
          <td class="budget">
            {{ row.item.id }}
          </td>
          <td class="budget">
            <span v-if="row.item.statuses.length > 0">
              {{ row.item.statuses[row.item.statuses.length - 1].name }}
            </span>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :pagination="pagination"
        :paginationLinks="paginationLinks"
        v-on:pagination-prev="getOrders($event)"
        v-on:pagination-num="getOrders(PaginationNum + $event)"
        v-on:pagination-next="getOrders($event)"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BarcodeBtnPrint from "../../components/BarcodeBtnPrint.vue";

export default {
  name: "print-barcode-table",
  components: {
    BarcodeBtnPrint,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      checkAll: 0,
      selected_orders: [],
      orders: "",
      statuses: "",
      targetStatus: "",
      newStatus: "",
      pagination: "",
      paginationLinks: "",
      PaginationNum: "",
    };
  },
  created() {
    this.getStatus();
  },
  methods: {
    getOrders: function ($pagination) {
      this.loading = true;
      if (this.targetStatus) {
        // To Change Pagination to filtering pagination
        axios
          .post(
            $pagination,
            { status: this.targetStatus },
            {
              headers: {
                "Accept-Language": "en",
              },
            }
          )
          .then((response) => {
            if (response.data.error) {
              // console.log("Error");
            } else {
              this.orders = response.data.data;
              this.pagination = response.data.meta;
              this.paginationLinks = response.data.links;
              this.PaginationNum = "companies/orders/search?page=";
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                // console.log(error.response.headers);
              } else if (error.request) {
                // console.log(error.request);
              } else {
                // console.log('Error', error.message);
              }
            } else {
              this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      } else {
        //If Not Filtering Orders
        axios
          .get($pagination, {
            headers: {
              "Accept-Language": "en",
            },
          })
          .then((response) => {
            if (response.data.error) {
              //
            } else {
              this.orders = response.data.data;
              this.pagination = response.data.meta;
              this.paginationLinks = response.data.links;
              this.PaginationNum = "companies/orders/index?page=";
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                //
              } else if (error.request) {
                //
              } else {
                //
              }
            } else {
              this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    // Get statues to filter
    getStatus: function () {
      this.loading = true;
      axios
        .get("companies/orders/statuses", {
          headers: {
            "Accept-Language": "ar",
          },
        })
        .then((response) => {
          if (response.data.error) {
            //
          } else {
            this.statuses = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            //
          } else if (error.request) {
            //
          } else {
            //
          }
        })
        .finally(() => (this.loading = false));
    },
    // Filter Orders
    getFilteredOrders: function () {
      // If choose 'all' in Filterd Box
      if (event.target.value == "all") {
        this.targetStatus = "";
        this.getOrders("companies/orders/index?page=1");
      } else {
        this.loading = true;
        this.targetStatus = event.target.value;
        const headers = {
          "Accept-Language": "ar",
        };
        axios
          .post(
            "companies/orders/search?page=1",
            { status: this.targetStatus },
            { headers: headers }
          )
          .then((response) => {
            if (response.data.error) {
              // console.log("Error");
            } else {
              this.orders = response.data.data;
              this.pagination = response.data.meta;
              this.paginationLinks = response.data.links;
              this.PaginationNum = "companies/orders/search?page=";
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                // console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
              }
            } else {
              this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    check_all: function () {
      this.selected_orders = [];
      if (event.target._modelValue == 1) {
        this.selected_orders = this.orders;
      }
    },
  },
};
</script>
