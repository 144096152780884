<template>
  <base-loader v-if="loading"></base-loader>
  <!-- For Print Only -->
  <!-- Small Print -->
  <div
    v-if="orderInfo && orderInfo.order_serial"
    class="print printSm hidden"
    id="printSm"
  >
    <barcode-invoice :orderInfo="orderInfo"></barcode-invoice>
  </div>
  <!-- Main Print -->
  <div
    v-if="orderInfo"
    class="print bg-white"
    :class="{ hidden: !shownInvoice }"
    id="printMe"
    ref="hide"
  >
    <div class="invoice-top-header p-4 d-flex justify-content-between">
      {{ $t("global.nqal") }}
      <div class="no-print">
        <button
          @click="printOrderInvoice()"
          class="btn btn-primary btn-sm print-btn"
        >
          <i class="fas fa-print"></i> {{ $t("orders.print invoice") }}
        </button>
        <button
          @click="shownInvoice = false"
          class="btn btn-primary btn-sm print-btn"
        >
          <i class="fas fa-long-arrow-alt-left"></i> {{ $t("global.back") }}
        </button>
      </div>
    </div>
    <header class="pb-0 invoice-header">
      <div class="container pt-4 pb-0 px-4">
        <div class="row">
          <div class="col-md-4 mb-4">
            <!-- <qrcode-vue :value="qrValue" :size="70" level="H" v-if="qrValue" /> -->
            <span class="date">#{{ orderInfo.id }}</span>
            <span
              class="date"
              v-for="(status, index) in orderInfo.statuses"
              :key="index"
            >
              <span v-if="index == orderInfo.statuses.length - 1">{{
                status.name
              }}</span>
            </span>
            <span class="date d-flex">
              {{ $t("auction.delivery_price") }}:
              <p class="mb-0 mx-2">
                {{ orderInfo.price + " " + $t("wallet.aed") }}
              </p>
            </span>
            <span class="date d-flex">
              {{ $t("global.payment method") }}:
              <p class="mb-0 mx-2">{{ orderInfo.payment_methods_name }}</p>
            </span>
            <p>{{ $t("global.use invoice num") }}</p>
          </div>
          <div class="col-md-4 d-flex justify-content-md-center mb-4">
            <div>
              <h6 v-if="orderInfo.order_type == 'send'">
                {{ $t("global.from") }}
              </h6>
              <h6 v-else-if="orderInfo.order_type == 'collect'">
                {{ $t("global.to") }}
              </h6>
              <div v-if="orderInfo.trader_detials">
                <span class="date">{{ orderInfo.trader_detials.name }}</span>
              </div>
              <div v-else-if="orderInfo.customer_detials">
                <span class="date">{{ orderInfo.customer_detials.name }}</span>
                <p class="mb-0">
                  {{ $t("tables.phone") }}:
                  {{ orderInfo.customer_detials.phone }}
                </p>
              </div>
              <span>{{ $t("tables.address") }}</span>
              <p class="mb-0">
                {{ orderInfo.address }}
              </p>
              <div :class="{ hidden: !printPDF }">
                <a
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    orderInfo.pickup_lat +
                    ',' +
                    orderInfo.pickup_long
                  "
                >
                  {{ $t("global.map_from") }}
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-md-end mb-4">
            <div>
              <h6 v-if="orderInfo.order_type == 'send'">
                {{ $t("global.to") }}
              </h6>
              <h6 v-else-if="orderInfo.order_type == 'collect'">
                {{ $t("global.from") }}
              </h6>
              <span class="date">{{ orderInfo.receiver_name }}</span>
              <p class="mb-0">
                {{ $t("tables.phone") }}: {{ orderInfo.receiver_phone }}
              </p>
              <span>{{ $t("tables.address") }}</span>
              <p class="mb-0">
                {{ orderInfo.state }} / {{ orderInfo.receiver_address }}
              </p>
              <div :class="{ hidden: !printPDF }">
                <a
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    orderInfo.receiver_lat +
                    ',' +
                    orderInfo.receiver_long
                  "
                >
                  {{ $t("global.map_to") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="invoice-body mt-5 bg-white">
      <div class="container">
        <div class="table-responsive">
          <table class="table invoice-table">
            <thead>
              <tr class="border-0">
                <th>#</th>
                <th>{{ $t("global.image") }}</th>
                <th>{{ $t("form.name") }}</th>
                <th>{{ $t("auction.description") }}</th>
                <th>{{ $t("form.price") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in orderInfo.orderItems" :key="index">
                <td>{{ item.id }}</td>
                <td>
                  <img
                    :src="'https://nqal.co/LiveNqal/public/' + item.image"
                    :alt="item.title + ' Image'"
                  />
                </td>
                <td>{{ item.title }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.value }}</td>
              </tr>
              <!-- <tr class="total-invoice">
                <td colspan="2">SUPTOTAL<br /><strong>100</strong></td>
                <td colspan="2">TAX FEES (20%)<br /><strong>20</strong></td>
                <td colspan="3" class="last-td">
                  TOTAL<br /><strong>120</strong>
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <p class="mt-5">*{{ $t("global.use invoice num") }}</p>
      </div>
    </div>
    <footer class="mt-5 text-center py-2 invoice-footer bg-white">
      <p class="mb-0">{{ $t("global.thanks") }}</p>
      <div class="ar-ltr">
        <a href="#" class="mx-2"
          ><i class="fas fa-globe"></i> https://www.nqal.co</a
        >
        <!-- <a href="tel:01001010" class="mx-2"><i class="fas fa-phone"></i> 010101010</a> -->
        <a href="info@nqal.co" class="mx-2"
          ><i class="fas fa-envelope"></i> info@nqal.co</a
        >
      </div>
      <div v-if="orderInfo.order_serial" class="barcode-svg">
        <barcode-generator
          :value="orderInfo.order_serial"
          :format="'CODE39'"
          :lineColor="'#000'"
          :height="'40'"
          :width="'2'"
          :elementTag="'svg'"
        />
      </div>
    </footer>
  </div>

  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>

  <div v-else>
    <div class="card shadow mb-4" :class="{ hidden: shownInvoice }">
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-default' : ''"
      >
        <div
          class="d-flex align-items-center justify-content-between flex-wrap"
        >
          <div class="mb-3 mb-lg-0">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              {{ $t("orders.order details") }}
            </h3>
          </div>
          <div class="text-right">
            <input
              :value="$t('orders.generate invoice')"
              type="button"
              @click="shownInvoice = true"
              class="btn btn-primary btn-sm print-btn mb-3 mb-lg-0"
            />
            <button
              @click="checkPrintPDF()"
              class="btn btn-primary btn-sm print-btn mb-3 mb-lg-0"
            >
              <i class="fas fa-file-pdf"></i> {{ $t("orders.print_pdf") }}
            </button>
            <button
              v-if="orderInfo.order_serial"
              @click="printBarCode()"
              class="btn btn-primary btn-sm print-btn mb-3 mb-lg-0"
            >
              <i class="fas fa-barcode"></i> {{ $t("orders.print_barcode") }}
            </button>
            <select
              class="btn btn-success btn-sm text-left"
              v-if="orderInfo.status_id == 1 && orderInfo.is_assigned == 0"
              @change="assignOrderToCourier($event)"
            >
              <option value="">{{ $t("orders.assign to courier") }}</option>
              <option
                v-for="(courier, index) in couriers"
                :key="index"
                :value="courier.id"
              >
                {{ courier.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table tablesorter table align-items-center table-flush">
          <!-- Table Head -->
          <tbody>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-circle-08 mr-2 text-main"></i>
                {{ $t("auction.type") }}
              </th>
              <td>
                {{ orderInfo.order_type }}
              </td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-circle-08 mr-2 text-main"></i>
                {{ $t("orders.sender name") }}
              </th>
              <td v-if="orderInfo.trader_detials">
                {{ orderInfo.trader_detials.name }}
              </td>
              <td v-else-if="orderInfo.customer_detials">
                {{ orderInfo.customer_detials.name }}
              </td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-mobile-button mr-2 text-main"></i>
                {{ $t("orders.sender phone") }}
              </th>
              <td v-if="orderInfo.trader_detials">
                {{ orderInfo.trader_detials.phone }}
              </td>
              <td v-else-if="orderInfo.customer_detials">
                {{ orderInfo.customer_detials.phone }}
              </td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-square-pin mr-2 text-main"></i>
                {{ $t("orders.sender address") }}
              </th>
              <td>{{ orderInfo.address }}</td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-circle-08 mr-2 text-main"></i>
                {{ $t("orders.receiver name") }}
              </th>
              <td>{{ orderInfo.receiver_name }}</td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-mobile-button mr-2 text-main"></i>
                {{ $t("orders.receiver phone") }}
              </th>
              <td>{{ orderInfo.receiver_phone }}</td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-square-pin mr-2 text-main"></i>
                {{ $t("orders.receiver address") }}
              </th>
              <td>
                {{ orderInfo.state + " - " + orderInfo.receiver_address }}
              </td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="fas fa-money-bill-wave-alt mr-2 text-main"></i>
                {{ $t("form.price") }}
              </th>
              <td>{{ orderInfo.price }}</td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="fas fa-credit-card mr-2 text-main"></i>
                {{ $t("global.payment method") }}
              </th>
              <td>{{ orderInfo.payment_methods_name }}</td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-user-run mr-2 text-main"></i>
                {{ $t("tables.courier name") }}
              </th>
              <td>
                <span>{{ orderInfo.courier_name }}</span>
                <a
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    orderInfo.courier_lat +
                    ',' +
                    orderInfo.courier_long
                  "
                  target="_blank"
                  class="btn btn-success btn-sm mx-3"
                  v-if="orderInfo.courier_lat && orderInfo.courier_long"
                >
                  <i class="fas fa-map-marker-alt"></i>
                </a>
              </td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-delivery-fast mr-2 text-main"></i>
                {{ $t("orders.shipping status") }}
              </th>
              <td class="ar-ltr">
                <span
                  v-for="(status, index) in orderInfo.statuses"
                  :key="index"
                >
                  <span
                    class="badge text-white"
                    :class="
                      index != orderInfo.statuses.length - 1
                        ? 'bg-danger'
                        : 'bg-success'
                    "
                  >
                    {{ status.name }}
                  </span>
                  <i
                    v-if="index != orderInfo.statuses.length - 1"
                    class="fas fa-arrow-right text-main mx-2"
                  ></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Order Items -->
    <div
      class="card shadow"
      :class="[type === 'dark' ? 'bg-default' : '', { hidden: shownInvoice }]"
    >
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              {{ title }}
            </h3>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <base-table
          class="table align-items-center table-flush"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="order"
        >
          <!-- Table Head -->
          <template v-slot:columns>
            <th>#</th>
            <th>{{ $t("global.image") }}</th>
            <th>{{ $t("form.name") }}</th>
            <th>{{ $t("auction.description") }}</th>
            <th>{{ $t("form.price") }}</th>
          </template>

          <!-- Table Body -->
          <template v-slot:default="row">
            <td class="budget">
              {{ row.item.id }}
            </td>
            <td class="budget">
              <img
                :src="'https://nqal.co/LiveNqal/public/' + row.item.image"
                :alt="row.item.title + ' Image'"
              />
            </td>
            <td class="budget">
              {{ row.item.title }}
            </td>
            <td class="budget">
              {{ row.item.description }}
            </td>
            <td class="budget">
              {{ row.item.value }}
            </td>
          </template>
        </base-table>
      </div>

      <div
        class="card-footer d-flex justify-content-end"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import BarcodeInvoice from "../../components//BarcodeInvoice.vue";
import BarcodeGenerator from "../../components/BarcodeGenerator.vue";
// import QrcodeVue from "qrcode.vue";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
export default {
  name: "order-table",
  components: {
    // QrcodeVue,
    BarcodeGenerator,
    BarcodeInvoice,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      shownInvoice: false,
      printPDF: false,
      orderInfo: "",
      order: "",
      couriers: "",
      qrValue: "",
    };
  },
  computed: {
    company_name() {
      return this.$store.getters.company_name;
    },
    company_logo() {
      return this.$store.getters.company_logo;
    },
  },
  created() {
    this.getOrder();
    this.moment = moment;
  },
  methods: {
    getOrder: function () {
      this.loading = true;
      axios
        .get("companies/orders/" + this.$route.params.id, {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = ["Please Try again"];
          } else {
            this.orderInfo = response.data.data[0];
            this.qrValue = response.data.data[0].qr_code;
            this.order = response.data.data[0].orderItems;
            console.log(this.orderInfo.timeSlot.from_time);
            if (
              this.orderInfo.status_id == 1 &&
              this.orderInfo.is_assigned == 0
            ) {
              this.getCouriers();
            }
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Couriers to assign
    getCouriers: function () {
      this.loading = true;
      axios
        .get("companies/couriers/index", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            this.couriers = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log(error.response);
          } else if (error.request) {
            // console.log(error.request);
          } else {
            // console.log('Error', error.message);
          }
        })
        .finally(() => (this.loading = false));
    },
    // Assign Order to Courier
    assignOrderToCourier: function () {
      this.loading = true;
      this.newStatus = event.target.value;
      const headers = {
        "Accept-Language": "ar",
      };
      axios
        .post(
          "companies/assign/courier",
          {
            _method: "put",
            order_id: this.orderInfo.id,
            courier_id: event.target.value,
          },
          { headers: headers }
        )
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = [response.data.message];
            return createToast(response.data.message, {
              type: "danger",
              timeout: 4000,
            });
          } else {
            if (response.data.status == 0) {
              return createToast(response.data.message, {
                type: "danger",
                timeout: 4000,
              });
            } else {
              this.getOrder();
              return createToast(response.data.message, {
                type: "success",
                timeout: 3000,
              });
            }
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Print Invoice
    printOrderInvoice() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printMe").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            ${prtHtml}
        </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.document.title = "invoice_" + this.orderInfo.id;
      WinPrint.print();
      //   WinPrint.close();
      this.printPDF = false;
    },
    // Print PDF
    checkPrintPDF() {
      this.printPDF = true;
      setTimeout(() => {
        this.printOrderInvoice();
      }, 100);
    },
    // Print Barcode
    printBarCode() {
      const prtHtml = document.getElementById("printSm").innerHTML;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
            @media print {
              @page {
                size: 4in 6in;
                margin: 0;
                padding: 0;
              }
              html, body { 
                font-family: 'Roboto', sans-serif;
                position: relative;
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 97%;
                margin: 0;
                padding: 0;
              }
              svg {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
              }
            }
            // Print Sm
            .sm-invoice {
              color: #000;
              background-color: #fff;
              width: 95mm;
              height: auto;
              border: 1px solid #000;
              border-radius: 8px;
              padding: 12px;
              direction: ltr;
              text-align: start;
            }
            .sm-invoice img {
              width: 80px;
              height: auto;
              object-fit: contain;
            }
            .sm-invoice .separator {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 6px;
              width: 1px;
              height: 100%;
              background-color: #000;
            }
            .printSm-box {
              border-bottom: 1px solid #000;
            }
            .printSm-box h6 {
              font-size: 14px;
              font-weight: 400;
            }
            .printSm-box p {
              font-size: 13px;
              font-weight: 600;
            }
            .printSm-box .in {
              width: 30px;
              height: 30px;
              background: #000;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }
            .no-b-b {
              border-bottom: 0 !important;
            }
            .sm-invoice svg {
              width: 100%;
            }
            .bg-gradient-main{
              background: linear-gradient(87deg, #65d1d1 0, #65a4d1 100%) !important;
              color: #fff;
              text-align: center;
              font-weight: 700;
            }
          </style>           
        </head>
        <body>
            ${prtHtml}
        </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.document.title = "invoice_" + this.orderInfo.id;
      WinPrint.print();
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100px;
  height: 80px;
  object-fit: contain;
}
.hidden {
  display: none;
}
.invoice-top-header {
  background-color: #3a3d50;
  color: #fff;
  font-size: 20px;
}
.invoice-header > .container {
  background-color: #f7f7f7;
}
.invoice-header .row > div {
  width: 33%;
}
.invoice-header svg {
  font-size: 40px;
  margin-bottom: 10px;
}
.invoice-header h6 {
  color: #576677;
}
.invoice-header span {
  color: #576677;
  display: block;
}
.invoice-header span.date {
  font-weight: 600;
  font-size: 17px;
}
.invoice-header p {
  font-size: 500;
  color: #576677;
}
.invoice-table td,
.invoice-table th {
  vertical-align: middle;
  white-space: nowrap;
}
.invoice-body table img {
  max-width: 70px;
}
.invoice-body .total-invoice {
  background-color: #f7f7f7;
}
.invoice-body .total-invoice td.last-td {
  background-color: #3a3d50 !important;
  color: #fff;
  font-size: 22px;
}
.invoice-body > p {
  color: #555;
  font-weight: 600;
}
.invoice-footer {
  border-top: 1px solid #eee;
}
.invoice-footer a {
  color: #555;
}
.invoice-footer a svg {
  color: #576677;
}
</style>
