<template>
  <div class="sm-invoice">
    <div class="printSm-box pb-3">
      <div class="row mx-0">
        <div class="col-3 d-flex align-items-center justify-content-center">
          <img src="../assets/images/nqal.png" />
        </div>
        <div class="col-6">
          <h6 class="mb-0">
            nqal app<br />
            fast delivery <br />
            shop at any place & ship to your place
          </h6>
          <div class="barcode-svg">
            <barcode-generator
              :value="orderInfo.order_serial"
              :format="'CODE39'"
              :lineColor="'#000'"
              :height="'40'"
              :width="'2'"
              :elementTag="'svg'"
            />
          </div>
          <!-- <vue3-barcode :value="orderInfo.order_serial" :height="70" /> -->
        </div>
        <div class="col-3 d-flex align-items-center justify-content-center">
          <img src="../assets/images/fargille.png" />
        </div>
      </div>
    </div>
    <div class="printSm-box">
      <div class="row">
        <div class="col-12 text-center">
          <h5 class="mb-0 py-2">{{ company_name }}</h5>
        </div>
      </div>
    </div>
    <div class="printSm-box">
      <div class="row m-0">
        <div class="col-6">
          <b v-if="orderInfo.order_type == 'send'"> From </b>
          <b v-else-if="orderInfo.order_type == 'collect'"> To </b> <br />
          <small>
            <div v-if="orderInfo.trader_detials">
              {{ orderInfo.trader_detials.name }} <br />
            </div>
            <div v-else-if="orderInfo.customer_detials">
              {{ orderInfo.customer_detials.name }} <br />
              {{ orderInfo.customer_detials.phone }}
            </div>
            {{ orderInfo.address }}
          </small>
        </div>
        <div class="col-6">
          <b v-if="orderInfo.order_type == 'send'"> To </b>
          <b v-else-if="orderInfo.order_type == 'collect'"> From </b> <br />
          <small>
            {{ orderInfo.receiver_name }} <br />
            {{ orderInfo.receiver_phone }} <br />
            {{ orderInfo.receiver_address }}
          </small>
        </div>
      </div>
    </div>
    <div class="printSm-box bg-dark py-2">
      <div class="row m-0">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-between">
            <p class="mb-0 text-white">Delivery Price</p>
            <p class="mb-0 text-white" v-if="orderInfo.payment_methods_id == 1">
              {{ orderInfo.price }}
            </p>
            <p
              class="mb-0 text-white"
              v-else-if="orderInfo.payment_methods_id == 2"
            >
              (Wallet) 0
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="printSm-box bg-dark py-2">
      <div class="row m-0">
        <div class="col-12">
          <div
            v-for="(item, index) in orderInfo.orderItems"
            :key="index"
            class="d-flex align-items-center justify-content-between"
          >
            <p class="mb-0 text-white">{{ item.title }}</p>
            <p class="mb-0 text-white">{{ item.value }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="printSm-box bg-dark py-2">
      <div class="row m-0">
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-between">
            <p class="mb-0 text-white">Total</p>
            <p class="mb-0 text-white" v-if="orderInfo.payment_methods_id == 1">
              {{
                (+orderInfo.price + +orderInfo.orderItems[0]["value"]).toFixed(
                  2
                )
              }}
            </p>
            <p
              class="mb-0 text-white"
              v-else-if="orderInfo.payment_methods_id == 2"
            >
              {{ orderInfo.orderItems[0]["value"] }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="printSm-box no-b-b">
      <table class="w-100 table table-bordered">
        <tr>
          <td>
            <p>
              <b
                >Date:
                {{ orderInfo.created_at }}
              </b>
            </p>
          </td>
          <td>
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <small>{{ orderInfo.state }}</small>
                <div class="d-flex align-items-center">
                  <b>UAE</b>
                  <b class="px-2">></b>
                  <b>UAE</b>
                </div>
              </div>
              <div>
                <span class="in">IN</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <p class="no-b-b mb-0" v-if="orderInfo.twenty_four_hour">
              <b>Time: </b>
              <b>{{ $t("auction.through_twenty_four_hour") }}</b>
            </p>
            <div class="no-b-b mb-0" v-else>
              <p class="mb-0">
                <b>Time From: </b>
                <b>{{ orderInfo.timeSlot.from_time }}</b>
              </p>
              <p class="mb-0">
                <b>Time To: </b>
                <b>{{ orderInfo.timeSlot.to_time }}</b>
              </p>
            </div>
            <p class="no-b-b mb-0">
              <b>Order No: </b>
              <b>{{ orderInfo.id }}</b>
            </p>
            <p class="no-b-b mb-0">
              <b>Payment Method: </b>
              <b>{{ orderInfo.payment_methods_name }}</b>
            </p>
          </td>
          <td class="p-0">
            <table class="w-100 table table-bordered mb-0">
              <tr>
                <td><b>Received Signature</b></td>
                <td><b>Signature</b></td>
              </tr>
              <tr>
                <td><br /><br /></td>
                <td><br /><br /></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div class="text-center">
      <img src="../assets/images/qrcode_app.png" width="150" />
      <p class="mb-0">Download App</p>
    </div>
    <div class="bg-gradient-main py-3">Shipping With NQAL</div>
  </div>
</template>

<script>
import BarcodeGenerator from "./BarcodeGenerator.vue";
export default {
  name: "Small Invoice",
  props: {
    orderInfo: [String, Number],
  },
  components: {
    BarcodeGenerator,
  },
  computed: {
    company_name() {
      return this.$store.getters.company_name;
    },
    company_logo() {
      return this.$store.getters.company_logo;
    },
  },
};
</script>
